import { useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { packColorMap } from "utils/app.util";
import "./ConfirmPackStep.css";

const ConfirmPackStep = ({ packs, selectedPack, formik }) => {
    const { t } = useTranslation();
    const [isFormValid, setIsFormValid] = useState(false);
    const packNameMap = new Map([
        ["1", 85],
        ["2", 87]
    ]);
    const packFrequencyMap = new Map([
        ["daily", 107],
        ["weekly", 108]
    ]);

    function getConfirmationString(pack) {
        if (!pack) {
            return;
        }

        const frequency = t(packFrequencyMap.get(pack.pack_frequency));

        return `${pack.data_pass} ${t('102')} ${frequency} ${t('103')}`;
    }

    useEffect(() => {
        const isPackValid = packs.find(pack => formik.values.packId === pack.id);
        setIsFormValid(isPackValid);
    }, [formik.values.packId, packs]);

    return (
        <>
            <div className="login-thumbnail2">
                <img src="/image/login_img.png" alt="login" />
            </div>
            <Form onSubmit={formik.handleSubmit}>
                <div className="offer-options">
                    <p className="fw-medium c-mb-10">{t('113')}</p>
                    {packs.map(pack => (
                        <div
                            key={pack.id}
                            className="offer"
                            style={selectedPack === pack ? {
                                border: `1px solid rgb(${packColorMap.get(pack.id)})`,
                                "--offer-color": `${packColorMap.get(pack.id)}`
                            } : {}}
                            onClick={() => formik.setFieldValue("packId", pack.id)}>
                            <Form.Check.Label htmlFor={pack?.id}>
                                <Badge className="fw-semibold" bg="custom" style={{ backgroundColor: `rgb(${packColorMap.get(pack.id)})` }}>
                                    {t(packNameMap.get(pack.id))}
                                </Badge>
                            </Form.Check.Label>
                            <div className="d-flex justify-content-end align-items-center">
                                <Form.Check.Input
                                    id={pack?.id}
                                    name="pack"
                                    type="radio"
                                    value={pack.id}
                                    onChange={formik.handleChange}
                                    checked={selectedPack?.id === pack.id}
                                />
                            </div>
                            {/* <p className="pack-rate fw-medium">{getConfirmationString(pack)}</p> */}
                            <p className="fw-bold">
                                {Math.round(pack.pack_price)} FCFA/
                                {pack.pack_frequency == "daily" ? t("118") : t('117')}
                                </p>
                        </div>
                    ))}
                </div>
                <button className="btn btn-primary btn-pm-custom" type="submit" disabled={!isFormValid || formik.isSubmitting}>
                    {t('77')}
                </button>
                {/* <div className="disclaimer">
                    <p className="mb-2 fw-medium">Disclaimer:</p>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum.</p>
                </div> */}
            </Form>
        </>
    )
}

export default ConfirmPackStep;

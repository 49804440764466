// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en.json';
import translationFR from '../locales/fr.json';
import translationPR from '../locales/pr.json';
import { getFunFact } from '../services/home'

const data = await getFunFact().then((data) => {
  return data
})

let totalFacts = 0
data.data.forEach((element, i) => {
  totalFacts = totalFacts + 1
  translationEN = { ...translationEN, [`fun_fact_${i + 1}`]: element.english }
  translationPR = { ...translationPR, [`fun_fact_${i + 1}`]: element.portuguese }
});

localStorage.setItem("total_facts", totalFacts)

const resources = {
  0: {
    translation: translationEN,
  },
  3: {
    translation: translationPR,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: '3', // default language
    interpolation: {
      escapeValue: false, // react already escapes values
    },
  });

export default i18n;
